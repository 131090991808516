import React from "react";
import { Redirect, Route, useRouteMatch } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "react-query";
import ParentWrapper from "../../components/ParentWrapper";
import PrivateRoute from "../../components/PrivateRoute";
import UserAppManager from "../../../src/pages/UserAppManager";
import Guides from "./Sampled_Guides/Guides";
const queryClient = new QueryClient();

const ParentSampledGuides = () => {
  let { path } = useRouteMatch();
  return (
    <QueryClientProvider client={queryClient}>
      <ParentWrapper path={path}>
        <PrivateRoute exact path={`${path}/guide`}>
          <Guides />
        </PrivateRoute>
        
        <PrivateRoute exact path={`${path}/users`}>
          <UserAppManager service={`${path}`} />
        </PrivateRoute>
        <Route>
          <Redirect to={`${path}/guide`} />
        </Route>
      </ParentWrapper>
    </QueryClientProvider>
  );
};

export default ParentSampledGuides;
