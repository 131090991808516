import { Divider, Grid, Paper, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import ListItemIcon from "@mui/material/ListItemIcon";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { styled, useTheme } from "@mui/material/styles";
import ServiceIcon from "../components/Navigation/ServiceList/ServiceIcon";
import BiService from "../services/BiService";
import { servicesSearchActions } from "../Store/services-search";
import { snackbarActions } from "../Store/snackbar";
import ServiceSendToLIMS from "./ServiceSendToLIMS";
// import ServiceCreateBulletin from "./ServiceCreateBulletin";
const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
  borderRadius: 3,
}));

const ServiceSummary = ({
  icon,
  color,
  name,
  description,
  route,
  sub_services,
}) => {
  const [openServiceModal, setOpenServiceModal] = useState(false);
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const history = useHistory();
  const dispatch = useDispatch();

  const handleClick = (event) => {
    if (route === "/jira_request") {
      history.push(route);
    } else {
      setAnchorEl(event.currentTarget);
    }
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleRoutes = (ss_route) => {
    if (ss_route === "modal") {
      setOpenServiceModal(true);
    } else {
      dispatch(servicesSearchActions.endSearch());
      history.push(ss_route);
    }
  };

  return (
    <>
      <Grid item xs={12} md={4} container sx={{ display: "flex" }}>
        <Box
          component={Paper}
          sx={{
            p: 2,
            "&:hover": { background: "rgb(10,10,10,0.05)", cursor: "pointer" },
            width: "100%",
            boxShadow: "0px 0px 2px 0px rgba(0,0,0,0.75);",
          }}
          onClick={handleClick}
        >
          <Box sx={{ display: "flex" }}>
            <ServiceIcon iconName={icon} color={color} />
            <Typography sx={{ ml: 1 }}>{name}</Typography>
          </Box>
          <Divider sx={{ width: "100%", mt: 1, mb: 1 }} />
          <Typography>{description}</Typography>
        </Box>
      </Grid>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            bgcolor:
              theme.palette.mode == "light"
                ? "#f5f9fa"
                : theme.palette.background.default,
            color: "secondary",
            overflow: "visible",
            filter: "drop-shadow(.8rem .8rem 8px rgba(0,0,0,0.32))",
            mt: 1,
            border:
              theme.palette.mode == "light"
                ? "2px solid #d4d8d8"
                : "2px solid rgba(200,200,200,0.12)",
            borderRadius: 3,
            "&:after": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              left: 20,
              width: 10,
              height: 10,
              bgcolor:
                theme.palette.mode == "light"
                  ? "#f5f9fa"
                  : theme.palette.background.default,
              borderTop:
                theme.palette.mode == "light"
                  ? "2px solid #d4d8d8"
                  : "2px solid rgba(200,200,200,0.12)",
              borderLeft:
                theme.palette.mode == "light"
                  ? "2px solid #d4d8d8"
                  : "2px solid rgba(200,200,200,0.12)",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        // transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "left", vertical: "bottom" }}
      >
        <Box sx={{ display: "flex", mr: 1, ml: 1 }}>
          <Box sx={{ minWidth: 200 }}>
            {sub_services.map((subservice) => (
              <StyledMenuItem
                key={subservice.subservice_id}
                onClick={() => handleRoutes(subservice.subservice_route)}
              >
                <ListItemIcon>
                  <ServiceIcon
                    iconName={subservice.icon}
                    color={
                      theme.palette.mode == "light" ? "#662D91" : "secondary"
                    }
                  />
                </ListItemIcon>
                <Typography
                  color={
                    theme.palette.mode == "light" ? "#662D91" : "secondary"
                  }
                  style={{ fontWeight: 700, fontSize: 12 }}
                >
                  {subservice.subservice_name.toUpperCase()}
                </Typography>
              </StyledMenuItem>
            ))}
          </Box>
        </Box>
      </Menu>
      {openServiceModal ? (
        <ServiceSendToLIMS
          openServiceModal={openServiceModal}
          setOpenServiceModal={setOpenServiceModal}
        />
      ) : null}
    </>
  );
};

const ServicesSearchPage = (props) => {
  const query = useSelector((state) => state.servicesSearch.query);
  const [searchResponse, setSearchResponse] = useState(null);
  const dispatch = useDispatch();

  useEffect(() => {
    BiService.searchServices(query)
      .then((resp) => {
        setSearchResponse(resp.data);
        console.log(resp.data);
      })
      .catch((err) => {
        dispatch(
          snackbarActions.showNotification({
            snackbarOpen: true,
            snackbarType: "error",
            snackbarMessage: `Failed Request: ${err}`,
          })
        );
      });
    return;
  }, [query]);

  return (
    <Grid container spacing={2}>
      <Grid item container xs={12}>
        <Box sx={{ width: "100%", pl: 2, pt: 2 }}>
          <Typography variant="h5" color="secondary">
            Services
          </Typography>
        </Box>
      </Grid>
      <Grid item container xs={12}>
        <Divider sx={{ width: "100%" }} />
      </Grid>
      {searchResponse &&
        searchResponse.services.map((service, index) => (
          <ServiceSummary
            key={index}
            icon={service.icon}
            name={service.service_name}
            description={service.description}
            color={service.color}
            route={service.route}
            sub_services={searchResponse.sub_services.filter(
              (subservice) => subservice.service_name === service.service_name
            )}
          />
        ))}
      {/* <Grid item container xs={24}>
        <Box sx={{ width: "100%", pl: 2, pt: 2 }}>
          <Typography variant="h5" color="secondary">
            Subservices
          </Typography>
        </Box>
      </Grid>
      <Grid item container xs={12}>
        <Divider sx={{ width: "100%" }} />
      </Grid>
      {searchResponse &&
        searchResponse.sub_services.map((subservice, index) => (
          <ServiceSummary
            key={index}
            icon={subservice.icon}
            name={subservice.subservice_name}
            description={subservice.description}
            color="secondary"
            route={subservice.subservice_route}
          />
        ))} */}
    </Grid>
  );
};

export default ServicesSearchPage;
