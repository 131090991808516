import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import ApiIcon from "@mui/icons-material/Api";
import ArchitectureOutlinedIcon from "@mui/icons-material/ArchitectureOutlined";
import CompareArrowsIcon from "@mui/icons-material/CompareArrows";
import DocumentScannerOutlinedIcon from "@mui/icons-material/DocumentScannerOutlined";
import DynamicFormIcon from "@mui/icons-material/DynamicForm";
import EditIcon from "@mui/icons-material/Edit";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import HandymanOutlinedIcon from "@mui/icons-material/HandymanOutlined";
import InventoryIcon from "@mui/icons-material/Inventory";
import KitchenIcon from "@mui/icons-material/Kitchen";
import ManageSearchIcon from "@mui/icons-material/ManageSearch";
import PersonAddAltRoundedIcon from "@mui/icons-material/PersonAddAltRounded";
import PlaylistAddIcon from "@mui/icons-material/PlaylistAdd";
import RocketLaunchIcon from "@mui/icons-material/RocketLaunch";
import SupervisedUserCircleIcon from "@mui/icons-material/SupervisedUserCircle";
import SupervisedUserCircleOutlinedIcon from "@mui/icons-material/SupervisedUserCircleOutlined";
import TableChartIcon from "@mui/icons-material/TableChart";
import SendIcon from "@mui/icons-material/Send";
import NewspaperIcon from "@mui/icons-material/Newspaper";
import PendingActionsIcon from "@mui/icons-material/PendingActions";
import StopCircleIcon from "@mui/icons-material/StopCircle";
import LinkOutlinedIcon from "@mui/icons-material/LinkOutlined";
import QueueOutlinedIcon from "@mui/icons-material/QueueOutlined";
import BackupTableOutlinedIcon from "@mui/icons-material/BackupTableOutlined";
import InsertChartIcon from "@mui/icons-material/InsertChart";
import LaunchIcon from "@mui/icons-material/Launch";
import PivotTableChartIcon from '@mui/icons-material/PivotTableChart';
import AutoStoriesIcon from '@mui/icons-material/AutoStories';
import FilePresentIcon from '@mui/icons-material/FilePresent';

const Icons = {
  AdminPanelSettingsIcon: AdminPanelSettingsIcon,
  HandymanOutlinedIcon: HandymanOutlinedIcon,
  DocumentScannerOutlinedIcon: DocumentScannerOutlinedIcon,
  KitchenIcon: KitchenIcon,
  SupervisedUserCircleIcon: SupervisedUserCircleIcon,
  ManageSearchIcon: ManageSearchIcon,
  FileUploadOutlinedIcon: FileUploadOutlinedIcon,
  ArchitectureOutlinedIcon: ArchitectureOutlinedIcon,
  RocketLaunchIcon: RocketLaunchIcon,
  DynamicFormIcon: DynamicFormIcon,
  FileDownloadOutlinedIcon: FileDownloadOutlinedIcon,
  TableChartIcon: TableChartIcon,
  SupervisedUserCircleOutlinedIcon: SupervisedUserCircleOutlinedIcon,
  EditIcon: EditIcon,
  InventoryIcon: InventoryIcon,
  PlaylistAddIcon: PlaylistAddIcon,
  PersonAddAltRoundedIcon: PersonAddAltRoundedIcon,
  CompareArrowsIcon: CompareArrowsIcon,
  SendIcon: SendIcon,
  NewspaperIcon: NewspaperIcon,
  PendingActionsIcon: PendingActionsIcon,
  StopCircleIcon: StopCircleIcon,
  LinkOutlinedIcon: LinkOutlinedIcon,
  QueueOutlinedIcon: QueueOutlinedIcon,
  BackupTableOutlinedIcon: BackupTableOutlinedIcon,
  InsertChartIcon,
  LaunchIcon,
  PivotTableChartIcon,
  AutoStoriesIcon,
  FilePresentIcon
};

const ServiceIcon = ({ iconName, color }) => {
  const IconComponent = Icons[iconName];

  if (!IconComponent) {
    return <ApiIcon />;
  }

  return <IconComponent sx={{ color: color ? color : "black" }} />;
};

export default ServiceIcon;
