import { Auth } from "aws-amplify";
import axios from "axios";
// import * as qs from "qs";

const ACS_API_BASE_URL = process.env.MAIN_API;

// console.log("PRINT", BI_API_BASE_URL);

class GuideService {

  async getSSguideRecords() {
    let myInit = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${(await Auth.currentSession())
          .getIdToken()
          .getJwtToken()}`,
      },
    };

    return await axios.get(ACS_API_BASE_URL + "/sampledsphere-guides/sampledsphere_guides_data", myInit);
  }

  async getSSGuideFile(file_name) {
    let myInit = {
      params: {
        file_key: `${file_name}`,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${(await Auth.currentSession())
          .getIdToken()
          .getJwtToken()}`,
      },
    };

    return await axios.get(
      ACS_API_BASE_URL + "/sampledsphere-guides/download_ss_guide_file",
      myInit
    );
  }
}

export default new GuideService();
